import Loadable from 'components/Loadable';

const pricing = {
  exact: true,
  path: '/plans',
  component: Loadable({
    loader: () => import('../views/plans'),
  }),
};

export default pricing;
