import Loadable from 'components/Loadable';
import {Redirect} from 'react-router';

const enquiry = {
  path: '/enquiry',
  component: Loadable({
    loader: () => import('../views/enquiry'),
  }),
  routes: [
    {
      exact: true,
      path: '/settings',
      component: () => <Redirect to="/enquiry" />,
    },
    {
      exact: true,
      path: '/settings/enquiry',
      component: Loadable({
        loader: () => import('../views/enquiry'),
      }),
    },
  ],
};

export default enquiry;
