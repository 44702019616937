import React from 'react';
import {Link} from 'react-router-dom';
import {IconFA} from 'components/Icons';
import {BaseButton} from 'components/Buttons';
import {Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from 'reactstrap';
import useManageSubscription from 'modules/membership/useManageSubscription';

function CheckoutPaymentFailedModal(props) {
  const {isOpen, toggle} = props;

  const {onManage, isManageLoading} = useManageSubscription();

  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <div className="d-flex align-items-center">
          <IconFA name="warning" className="text-danger font-size-md" />{' '}
          <span className="ml-2">Payment Failed!</span>
        </div>
      </ModalHeader>

      <ModalBody className="px-4 py-3">
        <p>
          An issue was encountered while processing your payment. Please update
          your payment method or{' '}
          <Link to="/contact" onClick={toggle}>
            contact
          </Link>{' '}
          our support team for assistance
        </p>
      </ModalBody>

      <ModalFooter>
        <BaseButton outline onClick={toggle} disabled={isManageLoading}>
          Cancel
        </BaseButton>

        <BaseButton
          onClick={onManage}
          color="primary"
          disabled={isManageLoading}
        >
          {isManageLoading ? <Spinner size="sm" /> : 'Update Payment Method'}
        </BaseButton>
      </ModalFooter>
    </Modal>
  );
}

export default CheckoutPaymentFailedModal;
