import Loadable from 'components/Loadable';
import {Redirect} from 'react-router';

const membership = {
  path: '/membership',
  component: Loadable({
    loader: () => import('../views/membership'),
  }),
  routes: [
    {
      exact: true,
      path: '/membership',
      component: () => <Redirect to="/membership/overview" />,
    },
    {
      exact: true,
      path: '/membership/overview',
      component: Loadable({
        loader: () => import('../views/membership/overview'),
      }),
    },
    {
      exact: true,
      path: '/membership/billing',
      component: Loadable({
        loader: () => import('../views/membership/billing'),
      }),
    },
    {
      path: '/membership/plans',
      component: Loadable({
        loader: () => import('../views/membership/plans'),
      }),
      routes: [
        {
          exact: true,
          path: '/membership/plans',
          component: Loadable({
            loader: () => import('../views/membership/plans/overview'),
          }),
        },
        {
          exact: true,
          path: '/membership/plans/checkout',
          component: Loadable({
            loader: () => import('../views/membership/plans/checkout'),
          }),
        },
      ],
    },
  ],
};

export default membership;
