import LockedFeatureModal from 'modules/membership/plans/LockedFeatureModal';
import {createContext, useContext, useState} from 'react';
import {useLocation} from 'react-router';

const LockedFeatureContext = createContext({});

function LockedFeatureProvider(props) {
  const {children} = props;
  const [isOpen, setIsOpen] = useState(false);
  const [persist, setPersist] = useState(false);
  const [type, setType] = useState('verification');
  const {pathname} = useLocation();

  function toggleModal(type, persist) {
    if (type) {
      setPersist(persist);
      setType(type || 'verification');
      setIsOpen((prev) => !prev);
    }
  }

  const redirect = pathname.replace(/^\//, '');
  return (
    <LockedFeatureContext.Provider value={{toggleLock: toggleModal}}>
      {children}

      <LockedFeatureModal
        isOpen={isOpen}
        toggle={toggleModal}
        redirect={redirect}
        type={type}
        persist={persist}
      />
    </LockedFeatureContext.Provider>
  );
}

const useLockedFeature = () => useContext(LockedFeatureContext);

export {LockedFeatureContext, LockedFeatureProvider, useLockedFeature};
