import urljoin from 'url-join';
import useQuery from 'hooks/useQuery';

import {useHistory, useLocation} from 'react-router';
import {alertByError} from 'utils/alerts';
import {
  postSubscribe,
  postSubscribeTrial,
  postSubscribeTrialToBasic,
} from 'api/subscriptions';
import {useMembership} from './MembershipContext';
import {generateUtmLink} from 'components/Links/UtmLink';
import {createContext, useContext, useState} from 'react';

import {IAF_MAIN_URL} from 'configs/Environment';
import {
  SUBSCRIPTION_PLANS_LEVEL_TYPE,
  SUBSCRIPTION_TYPE,
} from 'configs/membership';
import {buildUrlParams} from 'utils/string';
import {useAuth} from './AuthContext';
import {isEmpty} from 'lodash';
import CheckoutPaymentFailedModal from 'modules/membership/plans/checkout/CheckoutPaymentFailedModal';

const SubscriptionContext = createContext({});

function SubscriptionProvider(props) {
  const {children} = props;
  const {query} = useQuery();
  const history = useHistory();
  const {pathname} = useLocation();
  const {subscriptionLevel} = useMembership();
  const {token, handleLoginBySession} = useAuth();

  const {redirect} = query || {};
  const {utm_source, utm_medium, utm_content, utm_campaign} = query || {};

  const [isSubscribing, setIsSubscribing] = useState(false);
  const [isPaymentFailed, setIsPaymentFailed] = useState(false);

  const togglePaymentFailedModal = () => setIsPaymentFailed((prev) => !prev);

  const prevParams = new URLSearchParams(query).toString();

  async function onSubscribe(plan, accounts, additionalSeats) {
    const successUrlParams = {
      plan,
      success: true,
      redirect: redirect || 'dashboard',
      previous: SUBSCRIPTION_PLANS_LEVEL_TYPE[subscriptionLevel],
      current: plan,
    };

    const utmValues = {
      path: pathname,
      source: utm_source,
      medium: utm_medium,
      content: utm_content,
      campaign: utm_campaign,
      params: successUrlParams,
    };

    const utmParams = generateUtmLink(utmValues);

    const url = {
      success: urljoin(IAF_MAIN_URL, utmParams), // add utm params to track success rate
      cancel: urljoin(IAF_MAIN_URL, `/membership/plans/checkout?${prevParams}`),
    };

    setIsSubscribing(true);
    try {
      const payload = {
        account_ids: accounts,
        subscription_plans_type: plan,
        url,
        additional_seats: additionalSeats,
      };

      const {success_url} =
        plan === 'free_trial'
          ? await postSubscribeTrial(payload)
          : await postSubscribe(payload);

      if (success_url) {
        window.location.replace(success_url);
      }
    } catch (e) {
      alertByError(e);
    } finally {
      setIsSubscribing(false);
    }
  }

  async function upgradeToBasic() {
    setIsSubscribing(true);

    const url = buildUrlParams('/membership/plans', {
      success: true,
      redirect: redirect,
      previous: 'free_trial',
      current: SUBSCRIPTION_TYPE.basic,
    });

    try {
      const {data} = await postSubscribeTrialToBasic();

      if (data) {
        handleContinue(url);
      }
    } catch (e) {
      const {code} = e || {};

      if (code === 'incomplete_payment' || code === 'card_was_declined') {
        togglePaymentFailedModal();
      } else {
        alertByError(e);
      }
    } finally {
      setIsSubscribing(false);
    }
  }

  async function handleContinue(url) {
    const data = await handleLoginBySession(token);

    if (!isEmpty(data)) {
      history.push(url);
    }
  }

  return (
    <SubscriptionContext.Provider
      value={{
        onSubscribe,
        handleContinue,
        upgradeToBasic,
        isSubscribing,
      }}
    >
      {children}

      <CheckoutPaymentFailedModal
        isOpen={isPaymentFailed}
        toggle={togglePaymentFailedModal}
      />
    </SubscriptionContext.Provider>
  );
}

const useSubscription = () => useContext(SubscriptionContext);

export {useSubscription, SubscriptionContext, SubscriptionProvider};
