import {UUID_VERSION} from 'configs';
import {CONFIDENTIAL_TYPE} from 'configs/types';
import {isArray, isString} from 'lodash';
import isUUID from 'validator/es/lib/isUUID';

/**
 * Checks if str has prefix
 * @param {string} str
 * @param {string} prefix
 * @returns {boolean}
 */
export const hasPrefix = (str, prefix) => {
  return !!str && isString(str) && str.startsWith(prefix);
};

/**
 * Adds prefix if it doesn't exist
 * @param {string} str
 * @param {string} prefix
 * @returns {string}
 */
export const addPrefix = (str, prefix) => {
  return hasPrefix(str, prefix) ? str : prefix.concat(str);
};

/**
 * Removes prefix if it exists
 * @param {string} str
 * @param {string} prefix
 * @return {string}
 */
export const removePrefix = (str, prefix) => {
  if (!isString(str)) return false;

  return hasPrefix(str, prefix) ? str.slice(prefix.length) : str;
};

/**
 * Checks if the string is UUID
 * @param {string} str
 * @returns {boolean}
 */
export const uuidValidate = (str) => {
  return isUUID(str, UUID_VERSION);
};

export function checkConfidentialType(array = []) {
  if (!Array.isArray(array)) {
    return CONFIDENTIAL_TYPE['none'];
  }

  if (array.some((el) => el === CONFIDENTIAL_TYPE['all'])) {
    return CONFIDENTIAL_TYPE['all'];
  } else if (array.length === 0) {
    return CONFIDENTIAL_TYPE['none'];
  } else {
    return CONFIDENTIAL_TYPE['specified'];
  }
}

export function checkSpecified(field, array) {
  if (isArray(array)) return array.includes(field);
}

export const buildUrlParams = (pathname, params) => {
  if (
    !params ||
    typeof params !== 'object' ||
    Object.keys(params).length === 0
  ) {
    return pathname;
  }

  const queryParts = Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return queryParts ? `${pathname}?${queryParts}` : pathname;
};
